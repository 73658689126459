import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IS_BROWSER } from '~/constants/etc';
import getStorage from '~/libs/getStorage';
import { RootReducerState } from '~/modules';
import { localStorageAction } from '~/modules/LocalStorageModule';

type LocalStorageValueType = RootReducerState['localStorageReducer']['key'];

const useLocalStorage = (
    key: string
): [string | null, (value: string | null) => void] => {
    const dispatch = useDispatch();

    // 현재 reducer 에 저장된 값
    const storedValue = useSelector<RootReducerState, LocalStorageValueType>(
        ({ localStorageReducer }) => localStorageReducer[key]
    );

    // 값 없을 때 직접 local storage 에서 조회
    const value = useMemo(() => {
        if (storedValue === undefined) return getStorage().getItem(key);
        return storedValue;
    }, [key, storedValue]);

    const setItem = useCallback(
        (value: string | null) => {
            // reducer 에 값 세팅
            dispatch(localStorageAction.setItem(key, value));
        },
        [dispatch, key]
    );

    // 최초 reducer 에 localstorage 값 세팅
    useEffect(() => {
        dispatch(localStorageAction.initializeItem(key));
    }, [dispatch, key]);

    // storage 값 변경 이벤트 등록
    useEffect(() => {
        if (!IS_BROWSER) return;

        const storageEventHandler = (event: StorageEvent) => {
            const { key: storageKey, newValue } = event;
            if (storageKey === null) {
                dispatch(localStorageAction.initializeItem(key));
                return;
            }

            // key 확인
            if (storageKey !== key) return;
            if (storedValue === newValue) return;

            // reducer 에 값 세팅
            dispatch(localStorageAction.setItem(key, newValue));
        };

        window.addEventListener('storage', storageEventHandler);

        return () => {
            window.removeEventListener('storage', storageEventHandler);
        };
    }, [dispatch, key, storedValue]);

    return [value, setItem];
};

export default useLocalStorage;
