import { useCallback, useMemo } from 'react';

import LocalStorageKey from '~/constants/LocalStorageKey';
import useLocalStorage from '~/libs/hooks/useLocalStorage';

const useNotiEnabled = (): [boolean, (enable: boolean) => void] => {
    const [notiEnabled, setNotiEnabled] = useLocalStorage(
        LocalStorageKey.NOTI_ENABLED
    );
    const isEnable = useMemo(() => notiEnabled === 'true', [notiEnabled]);

    const setEnable = useCallback(
        (enable: boolean) => {
            setNotiEnabled(enable.toString());
        },
        [setNotiEnabled]
    );

    return [isEnable, setEnable];
};

export default useNotiEnabled;
