import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import Helmet from 'react-helmet';

import useFCMService from '~/libs/hooks/useFCMService';

const IndexPage: FC<PageProps> = () => {
    useFCMService();

    return (
        <div>
            <Helmet title="AfreecaTV notification register" />
        </div>
    );
};

export default IndexPage;
