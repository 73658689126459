import { useMemo } from 'react';

import LocalStorageKey from '~/constants/LocalStorageKey';
import useDeviceID from '~/libs/hooks/useDeviceID';
import useLocalStorage from '~/libs/hooks/useLocalStorage';
import useNotiEnabled from '~/libs/hooks/useNotiEnabled';
import FCMData from '~/types/FCMData';

const useStoredFCMData = (): FCMData => {
    const deviceID = useDeviceID();
    const [locale] = useLocalStorage(LocalStorageKey.NOTI_LOCALE);
    const [token] = useLocalStorage(LocalStorageKey.FCM_TOKEN);
    const [osVersion] = useLocalStorage(LocalStorageKey.NOTI_OS_VERSION);
    const [appVersion] = useLocalStorage(LocalStorageKey.NOTI_APP_VERSION);
    const [userInfo] = useLocalStorage(LocalStorageKey.NOTI_USER_INFO);

    return useMemo(
        () => ({
            deviceID,
            userInfo,
            locale,
            token,
            osVersion,
            appVersion
        }),
        [appVersion, userInfo, deviceID, locale, osVersion, token]
    );
};

export default useStoredFCMData;
