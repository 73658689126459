import { onMessage } from 'firebase/messaging';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Status from '~/constants/Status';
import getMessaging from '~/libs/fcm/browser/getMessaging';
import messageListener, { ListenerPosition } from '~/libs/fcm/messageListener';
import useFCMAllow from '~/libs/hooks/useFCMAllow';
import useNotiEnabled from '~/libs/hooks/useNotiEnabled';
import useStoredFCMData from '~/libs/hooks/useStoredFCMData';
import { RootReducerState } from '~/modules';
import { manageFCMAction } from '~/modules/ManageFCMModule';

interface ReducerStates {
    manageFCMStatus: RootReducerState['manageFCMReducer']['status'];
    allowFCMStatus: RootReducerState['allowFCMReducer']['status'];
}

const useFCMService = (sync = false) => {
    const dispatch = useDispatch();
    const savedStartToSync = useRef(false);

    const [allow] = useFCMAllow();

    const { manageFCMStatus, allowFCMStatus } = useSelector<
        RootReducerState,
        ReducerStates
    >(({ manageFCMReducer, allowFCMReducer }) => ({
        manageFCMStatus: manageFCMReducer.status,
        allowFCMStatus: allowFCMReducer.status
    }));

    // localstorage 에 저장된 fcm data 조회
    const storedFCMData = useStoredFCMData();
    const { deviceID, appVersion, osVersion, locale, token, userInfo } =
        storedFCMData;

    // localstorage 에 저장된 enabled 값 조회
    const [storedEnabled] = useNotiEnabled();

    // 최초 진입 시 token data 세팅
    const manageFCMReducerInitializable =
        allowFCMStatus === Status.SUCCESS && manageFCMStatus === Status.INITIAL;
    useEffect(() => {
        if (!manageFCMReducerInitializable) return;
        dispatch(
            manageFCMAction.initialize(storedFCMData, storedEnabled, sync)
        );
    }, [
        dispatch,
        manageFCMReducerInitializable,
        storedFCMData,
        storedEnabled,
        sync
    ]);

    // 이후 값 업데이트 시 fcm data 세팅
    savedStartToSync.current =
        savedStartToSync.current || manageFCMStatus === Status.SUCCESS;
    const startToSync = savedStartToSync.current;
    useEffect(() => {
        if (!startToSync) return;
        dispatch(manageFCMAction.updateFCMData());
    }, [
        dispatch,
        token,
        deviceID,
        locale,
        osVersion,
        appVersion,
        userInfo,
        startToSync
    ]);

    // 알림 허용 여부 변경 시 데이터 변경
    useEffect(() => {
        if (!startToSync) return;
        dispatch(manageFCMAction.updateEnabled(storedEnabled));
    }, [dispatch, storedEnabled, startToSync]);

    // 메세지 리스너 등록
    useEffect(() => {
        if (!allow) return;

        const messaging = getMessaging();
        onMessage(messaging, messageListener(ListenerPosition.FRONT));
    }, [allow]);
};

export default useFCMService;
