import { useEffect, useMemo } from 'react';

import LocalStorageKey from '~/constants/LocalStorageKey';
import useLocalStorage from '~/libs/hooks/useLocalStorage';

let memorizedDeviceID: string | null = null;

/**
 * 고유 device id 조회
 *
 * @returns {string} device id
 */
const useDeviceID = (): string | null => {
    const [storedDeviceID, setDeviceID] = useLocalStorage(
        LocalStorageKey.NOTI_DEVICE_ID
    );

    const deviceID = useMemo(() => {
        // 메모리에 올려진 값이 없을 경우 local storage 데이터로 조회
        if (memorizedDeviceID === null) memorizedDeviceID = storedDeviceID;
        return memorizedDeviceID;
    }, [storedDeviceID]);

    // device id 가 변경되었을 경우 다시 세팅
    useEffect(() => {
        if (deviceID === storedDeviceID || deviceID === null) return;
        setDeviceID(deviceID);
    }, [storedDeviceID, deviceID, setDeviceID]);

    return deviceID;
};

export default useDeviceID;
