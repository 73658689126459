import { MessagePayload } from 'firebase/messaging';

import { IS_PRODUCTION } from '~/constants/etc';
import ParameterError from '~/errors/ParameterError';
import errorHandler from '~/libs/errorHandler';
import getBrowserSWRegistration from '~/libs/fcm/browser/getSWRegistration';
import getSWRegistration from '~/libs/fcm/serviceWorker/getSWRegistration';
import NotificationData from '~/types/NotificationData';
import { isPushPayloadData } from '~/types/PushPayloadData';

export enum ListenerPosition {
    FRONT = 'FRONT',
    BACK = 'BACK'
}

const messageListener =
    (position: ListenerPosition) => async (payload: MessagePayload) => {
        if (!IS_PRODUCTION) {
            console.info(position, payload.data);
        }

        try {
            // 데이터 확인
            if (!payload.data)
                throw new ParameterError('Empty data', payload.data);

            // 알림 정보 세팅
            if (!isPushPayloadData(payload.data)) {
                throw new ParameterError('missing parameter.', payload.data);
            }

            const {
                title,
                type,
                body,
                url,
                tag,
                common_no: commonNo,
                gift_count: giftCount,
                icon,
                image
            } = payload.data;

            // 추가 데이터 (각종 notification 이벤트에 전달)
            const additionalData: NotificationData = {
                url,
                tag,
                type
            };
            if (commonNo && !isNaN(parseInt(commonNo)))
                additionalData.commonNo = parseInt(commonNo);
            if (giftCount && !isNaN(parseInt(giftCount)))
                additionalData.giftCount = parseInt(giftCount);

            const notificationOption: NotificationOptions = {
                data: additionalData,
                tag
            };
            if (body) notificationOption.body = body;
            if (icon) notificationOption.icon = icon;
            if (image) notificationOption.image = image;

            // registration 조회
            let serviceWorkerRegistration: ServiceWorkerRegistration;
            if (position === ListenerPosition.FRONT) {
                serviceWorkerRegistration = await getBrowserSWRegistration();
            } else {
                serviceWorkerRegistration = await getSWRegistration();
            }

            // 알림 보내기
            await serviceWorkerRegistration.showNotification(
                title,
                notificationOption
            );
        } catch (error) {
            errorHandler(error, 'error occurred in message listener');
        }
    };

export default messageListener;
