import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NotificationPermission from '~/constants/NotificationPermission';
import Status from '~/constants/Status';
import { RootReducerState } from '~/modules';
import { allowFCMAction } from '~/modules/AllowFCMModule';

type AllowDataType = RootReducerState['allowFCMReducer']['data'];
const useFCMAllow = (): [boolean, AllowDataType] => {
    const dispatch = useDispatch();

    type AllowFCMData = RootReducerState['allowFCMReducer'];
    const { data, status } = useSelector<RootReducerState, AllowFCMData>(
        ({ allowFCMReducer }) => allowFCMReducer
    );

    // 최초 fcm 기능 허용 체크 진행
    const allowFCMReducerInitializable = status === Status.INITIAL;
    useEffect(() => {
        if (!allowFCMReducerInitializable) return;
        dispatch(allowFCMAction.checkAllow(false));
    }, [dispatch, allowFCMReducerInitializable]);

    // 권한 허용 여부 확인
    const allow = useMemo(() => {
        if (!data) return false;

        const { permission, support } = data;
        return support && permission === NotificationPermission.GRANTED;
    }, [data]);

    return [allow, data];
};

export default useFCMAllow;
