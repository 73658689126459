import CustomError from '~/errors/CustomError';

class ParameterError extends CustomError {
    param?: Record<string, unknown>;

    constructor(message: string, param?: Record<string, unknown>) {
        super(message);

        this.param = param;
        this.name = 'ParameterError';
    }
}

export default ParameterError;
