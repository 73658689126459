interface PushPayloadData {
    title: string;
    type: string;
    url: string;
    tag: string;
    body?: string;
    common_no?: string;
    gift_count?: string;
}

export const isPushPayloadData = (item: unknown): item is PushPayloadData => {
    const pushPayloadData = item as PushPayloadData;
    const {
        title,
        type,
        url,
        tag,
        body,
        common_no: commonNo,
        gift_count: giftCount
    } = pushPayloadData;

    return (
        typeof title === 'string' &&
        typeof type === 'string' &&
        typeof url === 'string' &&
        typeof tag === 'string' &&
        ['string', 'undefined'].includes(typeof body) &&
        ['string', 'undefined'].includes(typeof commonNo) &&
        ['string', 'undefined'].includes(typeof giftCount)
    );
};

export default PushPayloadData;
